import React from "react";
import Parser from "html-react-parser";

const river = [
  "A5",
  "B5",
  "C5",
  "D5",
  "E5",
  "F5",
  "G5",
  "H5",
  "I5",
  "A6",
  "B6",
  "C6",
  "D6",
  "E6",
  "F6",
  "G6",
  "H6",
  "I6",
];
const palace = [
  "D1",
  "E1",
  "F1",
  "D2",
  "E2",
  "F2",
  "D3",
  "E3",
  "F3",
  "D10",
  "E10",
  "F10",
  "D9",
  "E9",
  "F9",
  "D8",
  "E8",
  "F8",
];

function Field(props) {
  const { onClick, location, chess, settings } = props;
  let piece = chess.pieces[location] || "";
  if (settings.confirmation) {
    if (chess.move.from && chess.move.to) {
      if (location === chess.move.from) {
        piece = "";
      }
      if (location === chess.move.to) {
        piece = chess.pieces[chess.move.from];
      }
    }
  }

  const historyClass =
    chess.history.length &&
    (chess.history[chess.history.length - 1].from === location ||
      chess.history[chess.history.length - 1].to === location)
      ? "lastMove"
      : "";
  const moveFromClass = chess.move.from === location ? "moveFrom" : "";
  const moveToClass =
    chess.move.from &&
    chess.moves &&
    chess.moves[chess.move.from] &&
    chess.moves[chess.move.from].includes(location)
      ? "moveTo"
      : "";

  const pieceHtml = () => {
    switch (piece) {
      case "Xe":
        return "&#36554;";

      case "Ma":
        return "&#39340;";

      case "Voi":
        return "&#35937;";

      case "Si":
        return "&#20181;";

      case "Tuong":
        if(chess.gameType ==="CO_DRAGON_VS_QUEEN") {
          return "&#9812;";
        }else{
          return "&#23559;";
        }

      case "Phao":
        return "&#30770;";

      case "Tot":
        return "&#21330;";
      case "Rong":
        return "&#128009;";
      case "Unknown":
        return "?";
      case "xe":
        return "&#36554;";

      case "ma":
        return "&#39340;";

      case "voi":
        return "&#35937;";

      case "si":
        return "&#20181;";

      case "tuong":
        if(chess.gameType ==="CO_QUEEN_VS_DRAGON") {
          return "&#9812;";
        }else{
          return "&#23559;";
        }
      case "phao":
        return "&#30770;";

      case "tot":
        return "&#21330;";
      case "rong":
        return "&#128009;";
      case "Queen":
        return "&#9819;"; //"&#128120;"; //&#9819;
      case "queen":
        return "&#9819;"; //"&#128120;"; //&#9819;

      case "unknown":
        return "?";
      default:
        let fakePieces = [
          "XeFake",
          "MaFake",
          "VoiFake",
          "SiFake",
          "PhaoFake",
          "TotFake",
          "xeFake",
          "maFake",
          "voiFake",
          "siFake",
          "phaoFake",
          "totFake",
        ];
        if (fakePieces.indexOf(piece) > -1) {
          return "?";
        }
        return "";
    }
  };

  function isCapital(ch) {
    return ch.charCodeAt() >= 65 && ch.charCodeAt() <= 90;
  }
  const isWhite = () => {
    if (piece) {
      return isCapital(piece) ? "white" : "black";
    }
    return "";
  };

  const diagonal =
    location === "D10" || location === "D3"
      ? "castle"
      : location === "F10" || location === "F3"
      ? "castle-mirrored"
      : "";

  const isPalace = () => (palace.indexOf(location) > -1 ? "palace" : "");

  const crossing = () => (
    <div className="crossing">
      <div className="top">
        <div className="left"></div>
        <div className="right"></div>
      </div>
      <div className="bottom">
        <div className="left"></div>
        <div className="right"></div>
      </div>
    </div>
  );

  const firstRow = () =>
    location[0] === "A"
      ? "first-column"
      : location[0] === "I"
      ? "last-column"
      : "";
  const firstColumn = () => {
    const num = location.replace(/[^0-9.]+/, "");
    return +num === 10 ? "first-row" : +num === 1 ? "last-row" : "";
  };

  const blackTotMarkers = ["A7", "C7", "E7", "G7", "I7"];
  const whiteTotMarkers = ["A4", "C4", "E4", "G4", "I4"];
  const blackPhaoMarkers = ["B8", "H8"];
  const whitePhaoMarkers = ["B3", "H3"];
  const markers = blackTotMarkers
    .concat(whiteTotMarkers)
    .concat(blackPhaoMarkers)
    .concat(whitePhaoMarkers);
  const bottomRightMarker = () =>
    markers.indexOf(location) > -1 ? "bottom-right-marker" : "";
  const bottomLeftMarker = () =>
    markers.indexOf(location) > -1 ? "bottom-left-marker" : "";
  const topRightMarker = () =>
    markers.indexOf(location) > -1 ? "top-right-marker" : "";
  const topLeftMarker = () =>
    markers.indexOf(location) > -1 ? "top-left-marker" : "";
  const bottomBorders = ["B6", "C6", "D6", "E6", "F6", "G6", "H6"];
  const topBorders = ["B5", "C5", "D5", "E5", "F5", "G5", "H5"];
  const isBottomRiver = () =>
    bottomBorders.indexOf(location) > -1 ? "bottom-river" : "";
  const isTopRiver = () =>
    topBorders.indexOf(location) > -1 ? "top-river" : "";

  const isRong = piece === "Rong" || piece === "rong" ? "rong" : "";
  return (
    <div
      style={{ position: "relative" }}
      className={`field ${moveFromClass} ${moveToClass} ${historyClass} ${isWhite()} ${firstRow()} ${firstColumn()} ${isBottomRiver()} ${isTopRiver()}`}
      onClick={onClick}
    >
      <span
        style={{
          position: "absolute",
          top: "0px",
          left: "4px",
          color: "red",
          fontSize: "14px",
        }}
      >
        {settings.debug && location}
      </span>{" "}
      {crossing()}
      <div className={`${!isPalace()} ${diagonal}`}></div>
      <div className={`sign ${isRong}`}>{Parser(pieceHtml())}</div>
      <div className={`${bottomRightMarker()}`}></div>
      <div className={`${bottomLeftMarker()}`}></div>
      <div className={`${topRightMarker()}`}></div>
      <div className={`${topLeftMarker()}`}></div>
      <div className="placeholder"></div>
    </div>
  );
}

export default Field;
