import React, { useState } from "react";
import { COMPUTER_LEVELS } from "./const/board";

function RightColumn(props) {
  const {
    onNewGameClick,
    onComputerLevelClick,
    onConfirmationToggleClick,
    onSoundToggleClick,
    onConfirmationClick,
    onDebugClick,
    chess,
    settings,
    loading,
    onGameTypeChanged,
    onToggleStyle,
  } = props;
  const BASIC_GAME_TYPES = {
    CO_TUONG: "Cờ tướng",
    CO_RONG: "Cờ rồng",
    // CO_NU_HOANG: "Cờ nữ hoàng",
  };
  const ADVANCED_GAME_TYPES = {
    CO_TUONG: "Cờ tướng",
    CO_RONG: "Cờ rồng",
    CO_NU_HOANG: "Cờ nữ hoàng",
    CO_DRAGON_VS_QUEEN: "Vua vs Tướng",
    CO_QUEEN_VS_DRAGON: "Tướng vs Vua",
    CO_UP: "Cờ úp",
  };

  const [advancedPassword, setAdvancedPassword] = useState("");
  const [GAME_TYPES, setGameTypes] = useState(BASIC_GAME_TYPES);
  const getMoreGames = () => {
    if (advancedPassword == "Tran") {
      setGameTypes(ADVANCED_GAME_TYPES);
    }
  };

  const isPasswordCorrect = () =>
    JSON.stringify(GAME_TYPES) !== JSON.stringify(ADVANCED_GAME_TYPES);

  return (
    <div>
      <div id="copyright">https://cotuong-online.com</div>
      {isPasswordCorrect() && (
        <div>
          <strong style={{ marginBottom: "12px" }}>
            Get password to play more games by supporting us.<br></br> Thanks :)
            Ủng hộ để phát triển trang web. Cảm ơn. <span>&#x1f91d;</span>
          </strong>
          <div style={{ display: "flex" }}>
            <input
              placeholder="Password for more games"
              value={advancedPassword}
              style={{ flex: 4, padding: "5px" }}
              onChange={(event) => setAdvancedPassword(event.target.value)}
            ></input>
            <span
              style={{
                flex: 2,
                fontSize: "15px",
                margin: "5px",
                padding: "5px",
                backgroundColor: "green",
                textAlign: "center",
              }}
              onClick={getMoreGames}
            >
              More games
            </span>
          </div>
        </div>
      )}
      <div id="level">
        <strong>Tro choi:</strong>
        {Object.keys(GAME_TYPES).map((typeKey) => {
          return (
            <strong
              className={`level ${
                chess.gameType === typeKey ? "selected" : ""
              }`}
              onClick={() => {
                onGameTypeChanged(typeKey);
                // if(typeKey == "CO_NU_HOANG"){
                //   delete COMPUTER_LEVELS.Cao_Thu;
                // }else{
                //   COMPUTER_LEVELS.Cao_Thu = 3;
                // }
              }}
              key={typeKey}
            >
              {GAME_TYPES[typeKey]}
              <input
                type="checkbox"
                readOnly
                checked={chess.gameType === typeKey}
              />
            </strong>
          );
        })}
      </div>
      <div id="new_game">
        <button
          disabled={!chess.history.length || loading}
          onClick={onNewGameClick}
        >
          <b>Bat dau tro choi moi</b>
        </button>
      </div>
      <div id="soundContainer">
        <b>Tieng dong:</b>
        <input
          id="sound"
          type="checkbox"
          checked={settings.sound ? "checked" : ""}
          onChange={onSoundToggleClick}
        />
      </div>
      <div id="debugContainer">
        <b>Ho tro:</b>
        <input
          id="sound"
          type="checkbox"
          checked={settings.debug ? "checked" : ""}
          onChange={onDebugClick}
        />
      </div>

      <div id="debugContainer">
        <b>Thay đổi bàn cờ:</b>
        <input
          id="sound"
          type="checkbox"
          checked={settings.kingchessStyle ? "checked" : ""}
          onChange={onToggleStyle}
        />
        {settings.kingchessStyle ? "Bàn cờ vua" : "Bàn cờ tướng"}
      </div>
      <div id="level">
        <strong>Trinh do cua doi thu:</strong>
        {Object.keys(COMPUTER_LEVELS).map((level) => {
          return (
            <strong
              className={`level ${
                settings.computerLevel === COMPUTER_LEVELS[level]
                  ? "selected"
                  : ""
              }`}
              onClick={() => onComputerLevelClick(COMPUTER_LEVELS[level])}
              key={level}
            >
              {level}
              <input
                type="checkbox"
                readOnly
                checked={settings.computerLevel === COMPUTER_LEVELS[level]}
              />
            </strong>
          );
        })}
      </div>
      <div id="history">
        <b>HISTORY</b>
        <br />
        {chess.history.map((record, index) => {
          return (
            <div key={index}>
              {record.from}-{record.to}
            </div>
          );
        })}
      </div>
      {/* <div id="confirmation">
                <p><b>Move confirmation</b></p>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={settings.confirmation ? 'checked' : '' }
                        onChange={onConfirmationToggleClick}
                    />
                        <span className="slider round"></span>
                </label>
                {settings.confirmation ?
                    <p><button onClick={onConfirmationClick} disabled={!(chess.move.from && chess.move.to)}><b>&#10004;</b></button></p> :
                    ''
                }

            </div> */}
      {/* <div id="copyright">
                <p>
                    This web site is using an open-source
                    &nbsp;<a href="https://www.npmjs.com/package/js-chess-engine" target="_blank"  rel="noopener noreferrer">js-chess-engine</a>.
                </p>
            </div> */}
    </div>
  );
}

export default RightColumn;
