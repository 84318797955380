import React, { useState, useEffect } from "react";
import Field from "./Field";
import PersistState from "./PersistState";
import RightColumn from "./RightColumn.js";
// import * as jsChessEngine from './lib/js-chess-engine.mjs'

import {
  NEW_GAME_BOARD_CONFIG,
  NEW_HIDDEN_GAME_BOARD_CONFIG,
  ROWS,
  COLUMNS,
  COLORS,
  SETTINGS,
  PERSIST_STATE_NAMESPACE,
  MOVE_SOUND,
} from "./const/board";
import { get } from "local-storage";
import "./App.css";
import { remove, set } from "local-storage";
const API_URIS = {
  MOVES: "moves",
  STATUS: "status",
  MOVE: "move",
  AI_MOVE: "aimove",
};
// const { move, status, moves, aiMove } = jsChessEngine
const moveSound = new Audio(`data:audio/wav;base64,${MOVE_SOUND}`);

function App() {
  const savedSettings = get(`${PERSIST_STATE_NAMESPACE}_settings`);
  const savedChess = get(`${PERSIST_STATE_NAMESPACE}_chess`);
  const [chess, setChess] = useState(
    savedChess && typeof savedChess === "object"
      ? Object.assign({}, NEW_GAME_BOARD_CONFIG, savedChess)
      : { ...NEW_GAME_BOARD_CONFIG }
  );
  const [settings, setSettings] = useState(
    savedSettings && typeof savedSettings === "object"
      ? Object.assign({}, SETTINGS, savedSettings)
      : { ...SETTINGS }
  );
  const [loading, setLoading] = useState(false);
  const board = getBoard();

  useEffect(() => {
    getMoves();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calcFieldSize = () => {};

  useEffect(() => {
    if (chess.turn === COLORS.BLACK && !chess.isFinished) {
      aiMove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chess.turn]);

  const simulateMove2 = () => {
    const pieces = chess.pieces;
    delete chess.pieces["I4"];
    chess.pieces["I5"] = "Voi";
    const newChess = { ...chess, pieces };
    setChess({ ...newChess });
    //aiMove();
  };

  const isKingChessMarker = () => {
    if (
      chess.gameType === "CO_NU_HOANG" ||
      chess.gameType === "CO_DRAGON_VS_QUEEN" ||
      chess.gameType === "CO_QUEEN_VS_DRAGON"
    ) {
      return "kingchessmarker";
    }
  };

  return (
    <div
      className={`cotuong ${isKingChessMarker()} ${
        settings.kingchessStyle ? "queen-chess" : ""
      } ${loading ? "loading" : ""}`}
    >
      <div className="column column_left">
        <div className="overlay">
          {/* <button onClick={simulateMove}>Simulate move</button> */}
          {loading && <span className="loading-spinner">Đang xử lý ...</span>}
          <div
            className={`board ${chess.isFinished ? "finished" : ""}`}
            disabled={chess.isFinished || loading}
          >
            {board}
          </div>
        </div>
      </div>
      <div className="column column_right">
        <div className="menu">
          <RightColumn
            chess={chess}
            settings={settings}
            loading={loading}
            onNewGameClick={() => handleNewGameClick()}
            onComputerLevelClick={handleChangeComputerLevelClick}
            onConfirmationToggleClick={() =>
              handleChangeConfirmationToggleClick()
            }
            onSoundToggleClick={() => handleChangeSoundToggleClick()}
            onDebugClick={() => handleDebugClick()}
            onToggleStyle={handleOnToggleStyle}
            onConfirmationClick={() => handleChangeConfirmationClick()}
            onGameTypeChanged={onGameTypeChanged}
          />
        </div>
        <PersistState settings={settings} chess={chess} />
      </div>
    </div>
  );

  function handleOnToggleStyle() {
    setSettings({ ...settings, kingchessStyle: !settings.kingchessStyle });
  }

  function handleDebugClick() {
    setSettings({ ...settings, debug: !settings.debug });
  }

  function getBoard() {
    const fields = [];
    Object.assign([], ROWS)
      .reverse()
      .map((row) => {
        return COLUMNS.map((column) => {
          const location = `${column}${row}`;
          //console.log("location",location)
          return fields.push(
            <Field
              location={location}
              key={`field-${location}`}
              onClick={() => handleFieldClick(location)}
              chess={chess}
              settings={settings}
            />
          );
        });
      });
    return fields;
  }

  async function handleFieldClick(field) {
    if (chess.move.from && chess.moves[chess.move.from].includes(field)) {
      chess.move.to = field;
      await setChess({ ...chess });
      if (settings.confirmation) {
      } else {
        return performMove(chess.move.from, chess.move.to);
      }
    } else if (chess.moves[field]) {
      setChess(Object.assign({}, chess, { move: { from: field } }));
    } else {
      setChess(Object.assign({}, chess, { move: { from: null } }));
    }
  }

  async function performMove(from, to) {
    chess.history.push({ from, to });
    chess.move.from = from;
    chess.move.to = to;
    const moves = await sendRequest(`${API_URIS.MOVE}?from=${from}&to=${to}`);
    setChess(Object.assign({}, chess, { move: {} }, moves));
    if (settings.sound) {
      moveSound.play();
    }
  }

  async function aiMove() {
    const aiMove = await sendRequest(
      `${API_URIS.AI_MOVE}?level=${settings.computerLevel}`
    );
    const from = Object.keys(aiMove)[0];
    const to = Object.values(aiMove)[0];
    return await performMove(from, to);
  }

  async function getMoves() {
    const moves = await sendRequest(API_URIS.MOVES);
    setChess(Object.assign({}, chess, { moves }));
  }

  async function handleNewGameClick(chessLocal = {}) {
    //remove(`${PERSIST_STATE_NAMESPACE}_chess`);

    let gameType =
      chessLocal.gameType || chess.gameType
        ? chessLocal.gameType || chess.gameType
        : "CO_TUONG";
    let pieces = { ...NEW_GAME_BOARD_CONFIG.pieces };
    if (gameType === "CO_RONG") {
      pieces["E9"] = "rong";
      pieces["E2"] = "Rong";
    } else if (gameType === "CO_UP") {
      pieces = NEW_HIDDEN_GAME_BOARD_CONFIG.pieces;
    } else if (gameType === "CO_NU_HOANG") {
      pieces["E9"] = "queen";
      pieces["E2"] = "Queen";
    } else if (gameType === "CO_QUEEN_VS_DRAGON") {
      pieces["E2"] = "Queen";
      pieces["E9"] = "rong";
    } else if (gameType === "CO_DRAGON_VS_QUEEN") {
      pieces["E2"] = "Rong";
      pieces["E9"] = "queen";
    }
    await setChess(
      Object.assign(
        chess,
        {
          pieces: {},
          history: [],
          gameType,
        },
        NEW_GAME_BOARD_CONFIG,
        { pieces }
      )
    );
    await getMoves();
  }

  async function sendRequest(url) {
    const backendUrl = "https://dragonchess.tran-consulting.com/"; //http://localhost:3999/"; //"https://dragonchess.tran-consulting.com/";
    await setLoading(true);
    try {
      const res = await fetch(`${backendUrl}${url}`, {
        method: "POST",
        body: JSON.stringify(chess),
        headers: { "Content-Type": "application/json" },
      });
      if (res.status !== 200) {
        throw new Error(`Server returns ${res.status}`);
      }
      await setLoading(false);
      return res.json().then((res) => {
        return res;
      });
    } catch (error) {
      await setLoading(false);
      chess.history.push({ from: "Error", to: error.message });
      return {};
    }
  }

  async function handleChangeComputerLevelClick(level) {
    await setSettings(Object.assign({}, settings, { computerLevel: level }));
  }

  async function onGameTypeChanged(gameType) {
    const newChess = { ...chess, gameType };

    //set(`${PERSIST_STATE_NAMESPACE}_chess`, newChess);
    await setChess(newChess);
    await handleNewGameClick(newChess);
  }

  async function handleChangeConfirmationToggleClick() {
    await setSettings(
      Object.assign({}, settings, {
        confirmation: settings.confirmation ? false : true,
      })
    );
  }

  async function handleChangeSoundToggleClick() {
    await setSettings(
      Object.assign({}, settings, { sound: settings.sound ? false : true })
    );
  }

  async function handleChangeConfirmationClick() {
    return performMove(chess.move.from, chess.move.to);
  }
}

export default App;
