export const COLUMNS = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
export const ROWS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
export const COLORS = {
  BLACK: "black",
  WHITE: "white",
};
export const COMPUTER_LEVELS = {
  // Monkey: 0,
  // Beginner: 1,
  Linh_Moi: 1,
  Tap_Choi: 2,
  Cao_Thu: 3
};
export const SETTINGS = {
  computerLevel: COMPUTER_LEVELS.Tap_Choi,
  confirmation: false,
  sound: true,
};
export const PERSIST_STATE_NAMESPACE = "cotuong_app";
export const NEW_GAME_BOARD_CONFIG = {
  turn: COLORS.WHITE,
  pieces: {
    A1: "Xe",
    B1: "Ma",
    C1: "Voi",
    D1: "Si",
    E1: "Tuong",
    F1: "Si",
    G1: "Voi",
    H1: "Ma",
    I1: "Xe",
    B3: "Phao",
    H3: "Phao",
    A4: "Tot",
    C4: "Tot",
    E4: "Tot",
    G4: "Tot",
    I4: "Tot",
    A10: "xe",
    B10: "ma",
    C10: "voi",
    D10: "si",
    E10: "tuong",
    F10: "si",
    G10: "voi",
    H10: "ma",
    I10: "xe",
    B8: "phao",
    H8: "phao",
    A7: "tot",
    C7: "tot",
    E7: "tot",
    G7: "tot",
    I7: "tot",
  },
  moves: {},
  move: {},
  history: [],
  isFinished: false,
  checkMate: false,
  castling: {
    whiteShort: true,
    blackShort: true,
    whiteLong: true,
    blackLong: true,
  },
  fullMove: 1,
  halfMove: 0,
};

export const NEW_HIDDEN_GAME_BOARD_CONFIG = {
  turn: COLORS.WHITE,
  pieces: {
    A1: "XeFake",
    B1: "MaFake",
    C1: "VoiFake",
    D1: "SiFake",
    E1: "Tuong",
    F1: "SiFake",
    G1: "VoiFake",
    H1: "MaFake",
    I1: "XeFake",
    B3: "PhaoFake",
    H3: "PhaoFake",
    A4: "TotFake",
    C4: "TotFake",
    E4: "Tot",
    G4: "TotFake",
    I4: "TotFake",
    A10: "xeFake",
    B10: "maFake",
    C10: "voiFake",
    D10: "siFake",
    E10: "tuong",
    F10: "siFake",
    G10: "voiFake",
    H10: "maFake",
    I10: "xeFake",
    B8: "phaoFake",
    H8: "phaoFake",
    A7: "totFake",
    C7: "totFake",
    E7: "tot",
    G7: "totFake",
    I7: "totFake",
  },
  moves: {},
  move: {},
  history: [],
  isFinished: false,
  checkMate: false,
  castling: {
    whiteShort: true,
    blackShort: true,
    whiteLong: true,
    blackLong: true,
  },
  fullMove: 1,
  halfMove: 0,
};
export const MOVE_SOUND =
  "UklGRnwFAABXQVZFZm10IBAAAAABAAEAQB8AAIA+AAACABAAZGF0YYgEAAANAG4A5AAmAO4ANAHfAM0AsACXAOMAQwEDAVUAzwC8AP0A0gClAPwA/QBxAFsAXADEAC4BtQDrAC4BNgEbAhUEfQjzEMQcQSzAOrpEOEeNPXYdmerdxeG0uqearpSzbLRfvMjRZPUpJrNTF3Izbw9dsDnNFxjy3doAzTrQU9IW12HTpdhu30brKPVm/+QMWBjJHh0bZBQADoYGRvy+9ATvROs76Lvgitcg08DV49+u8xwLoyD+Lm0xaC1VI30ZsRQeDcj/oO7B2A7IM776vv3Kp+BL9d4LNBoPIzAmNy61Mjc2RzawMgQnrxiQCDf37Omb4ararNZW1zPbu+Ii7Mr4AgcpFt0j0i0mMEUqNB2oDHH9Tu2X4rnYHtKizCvM09K23p7wWQVRGDIlEyiHJd0dYRAoB74At/q294/10/E38BLvCfCm9C79MAX0C2QPmRCKD8kObwxuCNYD3v1g+LryKuzF5+jlFObN6ATwA/et/i4FmAi0DE0PZBEPEi4SUg9tCpIELv+b+db08/EW8avxSvKz8lTzh/Rs9uT4Ovud/ab/igDPARICXgJCAocBYQG6AL//Sf+//2cBhQNoBpwJkAw3DtEO9Q2HDL4J/QR7/3v6UPUg8A3tcOr+6T/rHu7A8mv5pv8uBpwKlgzTDaINBAzMCXAGhAKo/9v8qvo8+f74K/mr+f/5Kvr/+c75GPk3+Lr3i/cL+OX4P/p5+139wv/9AUUEOQbRB3YIFQjxBtYFiQR7A2ICAAH+/s78PPp49wH10fIP8eTvOu9d77TwVfLQ9A33evnt+3D+rgE4BHoFbAYUBhwGPgXCBFQD3wAj/1z9DPyc+yX8Bv5D/xYA8f+n/nr9E/zt+gj6w/nf+fP5hfo6+7/86v5wAbEDqAWMBuYGPQczB8IGIgYFBSQDbQFPAKP/gP6f/Y79Of38/Qr/RAAoAUECAgPqAgcD+QJ+A/wCigL7AToB8QC7AFsBRwLlAiQD9QLRApsCOQLlAfgAGwD7/iP+xv2n/ev9y/6S/vr9Q/1w/Hf8p/xQ/Mr8Pf3q/T3/IAFAAzYEGQTyA9oDbwO0AqoBrQBD/6f9evy3++L6AftO+9j79/t9+7/63/m8+LL3HPfu9uX2U/fC9xz5LPsg/cH+BwAxAf0BGgI7An8CRAKxAqMCGQJaAY8Ae/9T/nX9SPxC+5f6JPrp+av5afm7+RT6WPoS+7z7nPyI/aL+yf+zAJEBgwJVA9YD+wOTA1sDqQJoAqQB+QCFAOH/Fv/3/Rb9avzs+/L7Gvxf/J/8jPx8/Jz8C/1F/eb9zf51/2kAcgEZAmMCowJyAhkCygEsAVUAj//i/mP+x/1u/XH9wf00/oD+Pf7G/c/97f0L/lT+nP70/uH+nf65/h3/uv/k/7X/Zf8F/1P++P2k/TH9wvxL/Mf7Yftb+4n77vsy/D78rPxD/Xf90/1l/qP+GP9m//L/sgAoAUUBZgG2AZIB0QE3AqUC1wLFAtcCmwL5Ad0B1AGAAd4BDwI3Am0CnAKsAmkC4AFaAYsAFQBi/2j/IP+8/kxJU1RUAAAASU5GT0lOQU0YAAAAY2hlc3NfbW92ZV9vbl9hbGFiYXN0ZXIASUFSVAYAAABtaDJvAABJQ1JEBgAAADIwMTYAAElHTlIMAAAAU291bmQgQ2xpcAAAaWQzIGwAAABJRDMEAEAAAABhAAAADAEgBQsuT0NfVENPTgAAAAsAAABTb3VuZCBDbGlwVElUMgAAABgAAABjaGVzc19tb3ZlX29uX2FsYWJhc3RlclREUkMAAAAFAAAAMjAxNlRQRTEAAAAFAAAAbWgybwA=";
